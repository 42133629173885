import * as React from 'react';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { actions as ssrActions } from '../services/ssrservice';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { States } from '@@services/services';
import { bindActionCreators } from 'redux';

export interface IContentInjectionProps {
    id?: string;
    inject: VisualTheme.IContentInjection;
    serverSide? : boolean;
    ssrActions?: typeof ssrActions;
    hostId?: string;
}

@connectwith((state: States.ISSRState) => {
    return {
        ssr: state,
    };
}, (dispatch) => {
    return {
        ssrActions: bindActionCreators(ssrActions, dispatch)
    };
})
export class ContentInjection extends React.PureComponent<IContentInjectionProps, any> {
    unmounted : boolean;

    placeholder = React.createRef<HTMLDivElement>();

    loadLinks(parentnode) {
        let promise = Promise.resolve();
        if (this.props.inject && this.props.inject.styles) {
            promise = this.props.inject.styles.reduce((prom, style) => {
                if (this.unmounted) return prom;

                return prom.then(() => {
                    if (this.unmounted) return;

                    let s: any;
                    if (style.innerContent) {
                        s = document.createElement("style") as any;
                        s.innerHTML = style.innerContent;
                    } else if (style.href) {
                        s = document.createElement("link");
                        s.rel = style.rel || "stylesheet";
                        s.href = style.href;
                    }
                    if (style.id) {
                        s.id = style.id;
                    }
                    if (style.rel) s.rel = style.rel;
                    if (style.tagAttributes) {
                        Object.keys(style.tagAttributes).forEach((attr) => {
                            const val = style.tagAttributes[attr];
                            if (val) {
                                s.setAttribute(attr, val);
                            }
                        });
                    }
                    if (style.content) {
                        s.setAttribute("content", style.content);
                    }

                    parentnode.appendChild(s);
                });
            }, promise);
        }

        return promise;
    }

    // loadScripts(parentnode: HTMLElement) {
    //     let promise = Promise.resolve() as Promise<any>;
    //     if (this.props.inject && this.props.inject.scripts) {
    //         promise = this.props.inject.scripts.reduce((prom, script) => {
    //             if (this.unmounted) return prom;

    //             return prom.then(() => {
    //                 if (this.unmounted) {
    //                     return;
    //                 }

    //                 if (script.id) {
    //                     const existing = parentnode.querySelector("script#script-" + script.id) as HTMLScriptElement;
    //                     if (existing) {
    //                         const scriptChanged = (script.innerContent && existing.text !== script.innerContent)
    //                             || (script.src && existing.src !== script.src);

    //                         if (!scriptChanged) {
    //                             return;
    //                         }
    //                     }
    //                 }

    //                 const s = document.createElement("script");
    //                 if (script.id) {
    //                     s.id = "script-" + script.id;
    //                 }
    //                 if (script.async) {
    //                     s.async = true;
    //                 }
    //                 parentnode.appendChild(s);

    //                 if (script.tagAttributes) {
    //                     Object.keys(script.tagAttributes).forEach((attr) => {
    //                         const val = script.tagAttributes[attr];
    //                         if (val) {
    //                             s.setAttribute(attr, val);
    //                         }
    //                     });
    //                 }

    //                 if (script.innerContent) {
    //                     try {
    //                         s.type = "text/javascript";
    //                         s.text = script.innerContent;
    //                     } catch (exception) {
    //                         console.warn("failed to load " + s.id);
    //                     }
    //                 } else if (script.src) {
    //                     s.src = script.src;
    //                     if (!script.async) {
    //                         return elementLoaded(s);
    //                     }
    //                 }
    //             });
    //         }, promise);
    //     }
    //     return promise;
    // }

    componentDidMount() {
        const parentnode = this.placeholder.current;
        if (parentnode && !parentnode.innerHTML) {
            this.loadLinks(parentnode).then(null, (err) => console.warn("error loading links", err));
            //this.loadScripts(parentnode).then(null, (err) => console.warn("error loading scripts", err));
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevProps:IContentInjectionProps) {
        if (prevProps.inject !== this.props.inject) {
            const prevStamp = prevProps.inject && JSON.stringify(prevProps.inject);
            const currentStamp = this.props.inject && JSON.stringify(this.props.inject);

            if (prevStamp !== currentStamp && this.placeholder.current) {
                // this.placeholder.current.innerHTML = "";
                this.loadLinks(this.placeholder.current).then(null, (err) => console.warn("error loading links", err));
                //this.loadScripts(this.placeholder.current).then(null, (err) => console.warn("error loading scripts", err));
            }
        }
    }

    render() {
        //let content;
        // if (!this.props.inject
        //     || (
        //         !(this.props.inject.scripts && this.props.inject.scripts.length)
        //         && !(this.props.inject.styles && this.props.inject.styles.length)
        //     )
        // ) {
        //     return null;
        // }

        //if (this.props.serverSide) {
        const content = [];
        if (this.props.inject && this.props.inject.scripts) {
            const scriptsToInject = [];
            this.props.inject.scripts.forEach((script, idx) => {
                if (this.props.serverSide) {
                    scriptsToInject.push({
                        id: script.id,
                        innerContent: script.innerContent,
                        src: script.src
                    });
                } else {
                    let scriptIsLoaded;
                    const serverSideScriptContainer = document.getElementById(this.props.hostId);
                    if (serverSideScriptContainer) {
                        scriptIsLoaded = !!serverSideScriptContainer.querySelector(`#script-${script.id}`);
                    }
                    if (!scriptIsLoaded) {
                        content.push(
                            <CustomScript
                                key={"script" + (script.id || idx)}
                                script={script}
                                serverSide={this.props.serverSide}
                            />
                        );
                    }
                }
            });
            if (scriptsToInject.length) {
                this.props.ssrActions.injectScripts(scriptsToInject);
            }
        }
        if (this.props.inject && this.props.inject.styles) {
            this.props.inject.styles.forEach((style, idx) => {
                /* if (style.content){
                    content.push(<link key={"style" + idx} rel={style.rel } content={style.content}></link>);
                } else */ if (style.href) {
                    content.push(<link
                        key={"style" + (style.id) + idx} rel={style.rel || "stylesheet"} href={style.href} />);
                } else if (style.innerContent) {
                    content.push(<style
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        key={"style" + (style.id) + idx} dangerouslySetInnerHTML={{ __html: style.innerContent }} />);
                }
            });
        }
        return <div ref={this.placeholder} id={this.props.id} className="injectionplaceholder" style={{ display: "none" }}>
            {content}
        </div>;
        // }

        // return <div ref={this.placeholder} id={this.props.id} className="injectionplaceholder" style={{ display: "none" }} />;
    }
}

interface ICustomScriptProps {
    script: VisualTheme.IScriptInjection;
    serverSide: boolean;
}

class CustomScript extends React.PureComponent<ICustomScriptProps, any> {
    scriptNode = React.createRef<HTMLScriptElement>();

    customScript: HTMLScriptElement = null;

    componentWillUnmount() {
        if (this.customScript) {
            console.log("deactivate script " + this.props.script?.id);
            document.body.removeChild(this.customScript);
            this.customScript = null;
        }
    }

    componentDidMount() {
        const { script } = this.props;
        const isFromSSR = this.scriptNode.current.dataset.ssr === "1";

        if (!isFromSSR) {
            console.log("activate script " + script.id);
            this.customScript = document.createElement("SCRIPT") as HTMLScriptElement;
            this.customScript.id = script.id ? "defered-script-" + script.id : null;
            if (script.async) {
                this.customScript.async = true;
            }

            document.body.appendChild(this.customScript);
            // this.scriptNode.current.parentNode.replaceChild(newscriptNode, this.scriptNode.current);
            // this.scriptNode = { current : newscriptNode };

            
            if (script.tagAttributes) {
                Object.keys(script.tagAttributes).forEach((attr) => {
                    const val = script.tagAttributes[attr];
                    if (val) {
                        this.customScript.setAttribute(attr, val);
                    }
                });
            }

            try {
                if (script.innerContent) {
                    this.customScript.text = script.innerContent;
                } else if (script.src) {
                    this.customScript.src = script.src;
                }
            } catch (ex) {
                console.error("error loading script", ex);
            }
        }
    }

    render() {
        const { script } = this.props;
        const props: any = {
            ref: this.scriptNode,
            id: script.id ? "script-" + script.id : null
        };

        if (this.props.serverSide) {
            props["data-ssr"] = "1";
        }
        
        if (script.async) {
            props.async = true;
        }

        if (script.tagAttributes) {
            Object.keys(script.tagAttributes).forEach((attr) => {
                const val = script.tagAttributes[attr];
                if (val) {
                    props[attr] = val;
                }
            });
        }

        if (script.src) {
            props.src = script.src;
        } else if (script.innerContent) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            props.dangerouslySetInnerHTML = { __html: script.innerContent };
        }

        return React.createElement("script", props);
    }
}
