import * as React from 'react';
import { BlocThemeContext } from '@inwink/visualconfiguration';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { withRouter } from 'react-router-dom';
import { getTargetPage } from '../navigation';
import { filterBlocContent } from '@@services/pageservice';
import { displayContent } from '@@services/itemshelpers';
import { ContentStyle } from '../contentstyle';
import { DynamicPageBlocContent } from './dynamicpagebloc.content';
import type { IDynamicPageBlocProps } from './dynamicpagebloc.props';
import { UrlServiceContext } from '../urlstatecontext';
import { useStore } from 'react-redux';
import { cssBackgroundImage } from '@@components/images/backgroundimage';
import { withI18nHelper } from '@inwink/i18n';
import { getImagePath } from '@@components/images/image';

import './dynamicpagebloc.less';

interface IDynamicPageBlocControlState {
    themeContext: any;
    useProgressiveBg: boolean;
    bgImageLoaded?: boolean;
}

@withI18nHelper()
class DynamicPageBlocControl extends React.PureComponent<IDynamicPageBlocProps, IDynamicPageBlocControlState> {
    blocbg = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        const template = this.props.bloctemplate;
        const theme = this.props.theme || template.theme || template.themePreset;

        this.state = {
            useProgressiveBg: false, //template.bgPicture && typeof (template.bgPicture) === "object",
            themeContext: {
                bloctheme: theme
            }
        };
    }

    componentDidMount() {
        const template = this.props.bloctemplate;
        if (template.bgPicture && typeof (template.bgPicture) === "object") {
            const pic = document.createElement("picture");
            const imgPath = getImagePath(
                template.bgPicture,
                this.props.i18n.supported,
                this.props.i18n.currentLanguageCode,
                this.props.event?.eventid,
                this.props.community?.communityid
            );
            const source = document.createElement("source");
            source.srcset = imgPath + "?o=webp&mw=" + document.body.clientWidth;
            source.type = "image/webp";
            pic.appendChild(source);
            const img = document.createElement("img");
            img.src = imgPath + "?mw=" + document.body.clientWidth;
            img.onload = () => {
                this.setState({
                    bgImageLoaded: true
                });
            };
            pic.appendChild(img);

            this.blocbg.current.appendChild(pic);
        }
    }

    componentDidUpdate(prevprops: IDynamicPageBlocProps) {
        if (prevprops.theme !== this.props.theme) {
            const template = this.props.bloctemplate;
            const theme = this.props.theme || template.theme;
            this.setState({
                themeContext: {
                    bloctheme: theme
                }
            });
        }
    }

    getClick() {
        let onClick;
        const bloc = this.props.bloctemplate;

        if (bloc.link) {
            if (bloc.link.target) {
                const linkTarget = getTargetPage(
                    bloc.link,
                    this.props.page.context,
                    (this.props as any).store
                );
                if (typeof linkTarget === "string") {
                    onClick = (arg: React.MouseEvent<any>) => {
                        arg.preventDefault();
                        this.props.history.push(this.props.urlservice.pageUrl(linkTarget));
                    };
                } else if (typeof linkTarget === "function") {
                    onClick = (arg: React.MouseEvent<any>) => {
                        arg.preventDefault();
                        linkTarget();
                    };
                }
            } else if (bloc.link.content) {
                onClick = (arg: React.MouseEvent<any>) => {
                    arg.preventDefault();
                    this.props.history.push(this.props.urlservice.pageUrl("content/" + bloc.link.content));
                };
            } else if (bloc.link.url) {
                onClick = (arg: React.MouseEvent<any>) => {
                    arg.preventDefault();
                    if (typeof bloc.link.url === "string") {
                        window.open(bloc.link.url);
                    }
                };
            } else if (bloc.link.mailto) {
                onClick = (arg: React.MouseEvent<any>) => {
                    arg.preventDefault();
                    if (typeof bloc.link.mailto === "string") window.open(bloc.link.mailto);
                };
            } else if (bloc.link.tel) {
                onClick = (arg) => {
                    arg.preventDefault();
                    if (typeof bloc.link.tel === "string") window.open(bloc.link.tel);
                };
            }
        }

        return onClick;
    }

    render() {
        const page = this.props.page;
        const template = this.props.bloctemplate;
        let theme = this.props.theme || template.theme;
        let blocthemeClass = this.props.blocthemeClass || "";

        if (template && (
            template.requireConnexion === true
            || template.requireConnexion === false
            || template.requireRegistration === true
            || template.requireRegistration === false
            || template.requireMembership === true
            || template.requireMembership === false
            || template.membershipActive === true
            || template.membershipActive === false
        )) {
            if ((global as any).disableAuthenticatedFeatures) {
                return <div className="empty" />;
            }

            if (displayContent(this.props.user, {
                event: this.props.event,
                community: this.props.community,
                requireConnexion: template.requireConnexion,
                requireRegistration: template.requireRegistration,
                membershipActive: template.membershipActive,
                requireMembership: template.requireMembership,
                membershipLevelIds: template.membershipLevelIds
            }) === false) {
                return <div className="empty" />;
            }
        }

        if (!this.props.theme && this.props.visualConfiguration) {
            if (template.themePreset) {
                blocthemeClass = "bloctheme-" + template.themePreset;
                theme = this.props.visualConfiguration.blocThemes
                    ? this.props.visualConfiguration.blocThemes.filter((t) => t.id === template.themePreset)[0]
                    : null;
            }
            if (!theme) {
                theme = this.props.visualConfiguration.defaultTheme;
            }
        }

        let blocContents;
        if (template && template.content) {
            blocContents = template.content.filter((content, contentIdx) => {
                return filterBlocContent(this.props.user, this.props.datacontext, content, contentIdx);
            }).map((content, contentIdx) => {
                let blocState;
                if (page.data && page.data[content.id]) {
                    blocState = page.data[content.id];
                }
                return <DynamicPageBlocContent
                    {...this.props}
                    key={"bloc" + content.id + "#" + contentIdx}
                    kind="component"
                    theme={theme}
                    contenttemplate={content}
                    blocState={blocState}
                />;
            });
        }

        if (!blocContents || blocContents.length === 0) {
            return <div className="empty" />;
        }
        let customStyles;
        if (template.customCSS) {
            customStyles = <ContentStyle
                blocid={template.id}
                css={template.customCSS}
                theme={this.props.theme}
            />;
        }

        const blocid = "bl-" + this.props.bloctemplate.id;
        const baseStyle: VisualTheme.IBlocItemTheme = Object.assign({}, this.props.rootStyles) as any;
        let background = null;
        if (template.bgPicture) {

            background = <>
                <style dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: cssBackgroundImage(
                        "#" + blocid,
                        template.bgPicture,
                        this.props.i18n.supported,
                        this.props.i18n.currentLanguageCode,
                        this.props.event?.eventid,
                        this.props.community?.communityid,
                        {
                            baseStyles: "background-size: cover; background-position: center;"
                        },
                        this.state.useProgressiveBg
                    )
                }}>
                </style>
                <div className={"dynamicbloc-background" + (this.state.bgImageLoaded ? " loaded" : "")} ref={this.blocbg}></div>
            </>;
        }
        const onClick = this.getClick();
        const classes = ["dynamicbloc-wrapper", "bloctheme", blocthemeClass];
        if (template.contentLayout) {
            classes.push("bloclayout-" + template.contentLayout);
        }
        if (template.fullsize) classes.push("fullsize");
        if (template.smallMargin) classes.push("smallmargin");
        if (template.customCSSClass) classes.push(template.customCSSClass);
        if ((template as any).__pagefooter) classes.push("bloc-footer");
        if (onClick) classes.push("clickable");
        if (template?.margin?.top) {
            classes.push(`margin-top-${template.margin.top}`);
        }
        if (template?.margin?.bottom) {
            classes.push(`margin-bottom-${template.margin.bottom}`);
        }
        if (template?.viewports?.length) {
            classes.push("has-viewport");
            template.viewports.forEach((viewport) => {
                classes.push(`display-on-${viewport}`);
            });
        }

        let customcontent;
        if (this.props.customBlocContent) {
            customcontent = React.createElement(this.props.customBlocContent, this.props);
        }

        return <React.Suspense fallback={<React.Fragment />}>
            <BlocThemeContext.Provider value={this.state.themeContext}>
                <div id={blocid} className={classes.join(" ")} style={baseStyle} onClick={onClick}>
                    {background}
                    {customStyles}
                    <div className="dynamicbloc-wrapper-contentblocs">
                        {blocContents}
                    </div>
                    {customcontent}
                </div>
            </BlocThemeContext.Provider>
        </React.Suspense>;
    }
}

const _Comp: React.ComponentClass<IDynamicPageBlocProps> = withRouter(DynamicPageBlocControl as any) as any;
// eslint-disable-next-line import/no-mutable-exports
export let DynamicPageBloc = function (props) {
    const store = useStore();
    return <UrlServiceContext.Consumer>
        {() => <_Comp {...props} store={store} />}
    </UrlServiceContext.Consumer>;
};

export function hookDynamicPageBloc(callback) {
    DynamicPageBloc = callback(_Comp);
}
