import { IAssetEntity } from "@@data/assets";
import { getImagePath } from "./image";

export function cssBackgroundImage(
    selector: string,
    image: string | IAssetEntity,
    languages: string[], displayLanguage: string,
    eventid?: string,
    communityid?: string,
    options?: {
        baseStyles?: string
    },
    progressiveBg?: boolean,
) {
    if (!image) {
        return "";
    }

    const buffer = [];
    if (typeof image === "string") {
        buffer.push(`${selector} { background-image: url('${image}'); ${options?.baseStyles || ""}}`);
    } else {
        if (options?.baseStyles) {
            buffer.push(`${selector} { ${options?.baseStyles }}`);
        }
        const imagePath = getImagePath(image, languages, displayLanguage, eventid, communityid);
        
        if (progressiveBg) {
            const imgSet = [
                `url('${imagePath}?o=webp&b=8&mw=100&q=70') type('image/webp')`,
                `url('${imagePath}?mw=100&b=8&q=70')`,        
            ];
            buffer.push(`${selector} { background-image: image-set(${ imgSet.join(", ")}); }`);
            
        } else {
            addForMedia(buffer, selector, imagePath, 0, 480);
            addForMedia(buffer, selector, imagePath, 480, 800);
            addForMedia(buffer, selector, imagePath, 800, 1024);
            addForMedia(buffer, selector, imagePath, 1024, 1280);
            addForMedia(buffer, selector, imagePath, 1280, 1600);
            addForMedia(buffer, selector, imagePath, 1600, 1920);
            addForMedia(buffer, selector, imagePath, 1920);
        }
    }

    return buffer.join("\r\n");
}

function addForMedia(buffer: string[], selector: string, imagePath: string, minwith?: number, maxWidth?: number, blur?: number) {
    const imgSet = [
        `url('${imagePath}?o=webp${maxWidth ? `&mw=${maxWidth}` : ''}${blur ? `&b=${blur}` : ''}') type('image/webp')`,
        `url('${imagePath}${maxWidth ? `?mw=${maxWidth}${blur ? `&b=${blur}` : ''}` : `${blur ? `?b=${blur}` : ''}`}}')`,        
    ];
    const max = maxWidth ? `and (max-width: ${maxWidth}px)` : '';
    const bgImage = `${selector} { background-image: image-set(${ imgSet.join(", ")}); }`;
    buffer.push(`@media screen and (min-width: ${minwith}px) ${max} { ${bgImage} }`);    
}