import { States } from './services';

const INITIAL_STATE: States.ISSRState = {
};

export function ssrReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "SSR_CLEARFIRSTPASS": {
            const newstate = Object.assign({}, state);
            delete newstate.firstPass;
            return newstate;
        }
        case "SSR_INJECTSCRIPTS": {
            const newstate = Object.assign({}, state);
            newstate.scripts = action.payload;
            return newstate;
        }
        default:
            return state;
    }

    return state;
}

export const actions = {
    clearFirstPass() {
        return (dispatch) => {
            dispatch({
                type: "SSR_CLEARFIRSTPASS"
            });
        };
    },
    injectScripts(scripts: any[]) {
        return (dispatch) => {
            dispatch({
                type: "SSR_INJECTSCRIPTS",
                payload: scripts
            });
        };
    }
};
