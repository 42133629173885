import * as React from 'react';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { withPopoverManagerHOC, IPopoverManager } from '@inwink/modals/popovermgr';
import type { States } from '../../services/services';
import type { IDynamicPageProps } from './dynamicpage.props';
import { DynamicPageBloc } from './dynamicpagebloc';

import './dynamicpagesidebar.less';

export interface IDynamicPageSidebarProps extends IDynamicPageProps {
    popovermgr?: IPopoverManager;
    page: States.ICurrentPageState;
    blocs: VisualTheme.IBlocTemplateBase[];
    datacontext: Entities.IPageDataContext;
    customcontext?: any;
    editable?: boolean;
    onLayoutInit?: () => void;
    onPageLoading?: (loading: boolean) => void;
    sidebarVisible: boolean;
}

class DynamicPageSidebarComponent extends React.Component<IDynamicPageSidebarProps, any> {
    /*
    componentDidMount() {
        if (this.props.blocs?.length && !this.props.sidebarVisible) {
            this.props.userChatActions.offsetsChanged([100]);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.blocs !== this.props.blocs && this.props.blocs?.length && !this.props.sidebarVisible) {
            this.props.userChatActions.offsetsChanged([100]);
        }
    }
    */

    openSidebar = () => {
        import("./dynamicpagesidebar.modal").then((mod) => {
            mod.showSideBarModal(this.props);
        });
    };

    render() {
        const { page, blocs, sidebarVisible } = this.props;

        if (!blocs || !blocs.length) {
            return null;
        }

        if (!sidebarVisible) {
            const icon = (page.template.config.sidebar.icon) || "inwink-idea";

            return <div className="dynamicpage-sidebar-trigger bloctheme">
                <button type="button" onClick={this.openSidebar}>
                    {icon ? <i className={icon} /> : null}
                </button>
            </div>;
        }

        return <div className="dynamicpage-blocscontainer dynamicpage-sidebar">
            {renderBlocs(this.props)}
        </div>;
    }
}

export const DynamicPageSidebar: (props:IDynamicPageSidebarProps) =>any = withPopoverManagerHOC(
    DynamicPageSidebarComponent
) as any;

export function renderBlocs(props: IDynamicPageSidebarProps) {
    const { page, blocs } = props;

    return blocs.map((template) => {
        const templateToRender = template;
        return <DynamicPageBloc
            {...props}
            key={"bloc" + template.id}
            page={page}
            bloctemplate={templateToRender}
            datacontext={props.datacontext}
            onLayoutInit={props.onLayoutInit}
            onPageLoading={props.onPageLoading}
        />;
    });
}
