import { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import { States } from '../../services/services';
import { IDynamicPageProps } from './dynamicpage.props';
import { getTimeZoneDate } from '../displaytimezonedate';

export function pageDataContext(props: IDynamicPageProps) {
    const page = props.pages[props.template] as States.ICurrentPageState;

    const datacontext = {
        i18nstate: props.i18n,
        eventstate: props.event,
        userstate: props.user,
        event: props.event && props.event.detail,
        rootwebsite: props.rootwebsite && props.rootwebsite.detail,
        rootwebsitestate: props.rootwebsite,
        isApp: false,
        visualConfiguration: props.visualConfiguration,
        user: props.user && props.user.currentUser ? props.user.currentUser.detail : null,
        entity: page && page.context ? page.context.entity : null,
        entityKind: page && page.context ? page.context.entityKind : null,
        fieldtemplate: page && page.context ? page.context.fieldtemplate : null,
        customcontext: props.customcontext,
        location: props.location,
        match: props.match,
        community: props.community && props.community.detail,
        communitystate: props.community
    } as Entities.IPageDataContext;

    (datacontext as any).dates = {
        current: moment()
    };

    if (props.event?.detail) {
        (datacontext as any).dates.event = {
            start: props.event.detail.startDate ? moment(props.event.detail.startDate) : null,
            end: props.event.detail.endDate ? moment(props.event.detail.endDate) : null,
            startZone: props.event.detail.startDate
                ? getTimeZoneDate(props.event.detail.startDate as any, props.event.detail, props.i18n)
                : null,
            endZone: props.event?.detail.endDate
                ? getTimeZoneDate(props.event.detail.endDate as any, props.event.detail, props.i18n)
                : null,
        };

        if (page?.context?.entity && page?.context?.entityKind?.toLowerCase() === "session") {
            const session = page.context.entity as Entities.ISession;
            const timeslot = session.timeslots && session.timeslots[0];
            if (timeslot) {
                (datacontext as any).dates.session = {
                    start: timeslot.startDate ? moment(timeslot.startDate) : null,
                    end: timeslot.endDate ? moment(timeslot.endDate) : null,
                    startZone: timeslot.startDate
                        ? getTimeZoneDate(timeslot.startDate as any, props.event.detail, props.i18n)
                        : null,
                    endZone: timeslot.endDate
                        ? getTimeZoneDate(timeslot.endDate as any, props.event.detail, props.i18n)
                        : null
                };
            }
        }
    }

    if (props.match && props.match.params) {
        const id = props.match.params.exhibitorworkspaceid;
        if (id) {
            (datacontext as any).exhibitorId = id;
            (datacontext as any).exhibitor = props.event
        && props.event.data
        && props.event.data.exhibitors.data.filter((e) => e.id === id)[0];
        }
    }

    const currentUser = props.user?.currentUser?.detail;
    if (page && page.context && page.context.entityKind && page.context.entityid && currentUser) {
        if (page.context.entityKind === 'session' || page.context.entityKind === 'Session') {
            const alluserFavorites = props.user.currentUser.data.registeredSessions.data;
            const userfavorite = alluserFavorites.filter((rs) => rs.sessionId === page.context.entityid)[0];
            (datacontext as any).favorite = userfavorite;
            (datacontext as any).isInFavorites = userfavorite && (userfavorite.status === 'Active');
        }

        if (page.context.entityKind === 'exhibitor' || page.context.entityKind === 'Exhibitor') {
            const alluserFavorites = props.user.currentUser.data.favoriteExhibitors.data;
            const userfavorite = alluserFavorites.filter((rs) => rs.exhibitorId === page.context.entityid)[0];
            (datacontext as any).favorite = userfavorite;
            (datacontext as any).isInFavorites = userfavorite && (userfavorite.status === 'Active');
        }
        
        if (page.context.entityKind === 'eventtheme' || page.context.entityKind === 'EventTheme') {
            const alluserFavorites = props.user.currentUser.data.favoriteEventThemes.data;
            const userfavorite = alluserFavorites.filter((rs) => rs.eventThemeId === page.context.entityid)[0];
            (datacontext as any).favorite = userfavorite;
            (datacontext as any).isInFavorites = userfavorite && (userfavorite.status === 'Active');
        }
    }

    return datacontext;
}
